<template>
  <v-row class="fill-height mt-10">
    <v-col class="align-center">
      <v-overlay
          v-if="$auth.isLoading"
      ></v-overlay>
      <v-row class="justify-center">
        <v-col cols="12" lg="4" md="4" sm="8" class="text-center">
          <h1>{{ company.name }}</h1>
          <br/>
          <p>Our services are in a testing phase, to create a new account please reach out to <a :href='"mailto:"+company.contact'>{{ company.contact }}</a>
          <br/>
          <br/>
            <router-link
                v-if="$auth.isAuthenticated"
                to="/dashboard"
            >
              Go To Dashboard
            </router-link>
            <login-button
                v-else
            ></login-button>
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import LoginButton from "../components/common/LoginButton";
export default {
  name: 'Default',
  components: {LoginButton},
  props: {
    company: {
      type: Object,
      default: () => {
        return {
          company: 'Doctor Portal',
          contact: 'info@dayvue.health'
        }
      }
    },
  },
  data: () => ({
    isLoading: false,
    received: false
  })
};
</script>
